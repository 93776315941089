import React, { useEffect, useRef, useState } from 'react';

import Button from 'components/buttons/Button';
import Calendar from 'components/Calendar';
import Icon from 'components/icons/Icon';
import EditorInput from 'components/inputs/EditorInput';
import Input from 'components/inputs/Input';
import TextArea from 'components/inputs/TextArea';
import UploadFileCropper from 'components/inputs/UploadFileCropper';
import UploadFileImageSimple from 'components/inputs/UploadFile/UploadFileImageSimple';
import UploadFileMultipleImage from 'components/inputs/UploadFile/UploadFileMultipleImage';
import UploadFileVideo from 'components/inputs/UploadFile/UploadFileVideo';
import NavigationLink from 'components/links/NavigationLink';
import Spinner from 'components/Spinner/Spinner';
import { RequiredString } from 'helpers/yupHelper';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { Controller, FieldErrors, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import {
  Igroup,
  IGroupAmssador
} from 'redux/slices/GroupAmbassador/GroupAmbassador.interface';
import { CampaignData } from 'services/rest/Campaign/createCampaign';
import { Colors } from 'styles/Constants';
import { Hourt, Minutes } from 'Utils/Hourt';
import * as yup from 'yup';

import {
  Column,
  ErrorSpan,
  FlexSection,
  FormTextArea,
  FormWrapper,
  GridComponent,
  InputsWrapper,
  LabelForm,
  MarginButton,
  NetworkFlexContainer,
  NumberWrapper,
  SocialNetworkContent,
  TimeZoneCampaign,
  StyledCheckBoxTerms
} from './Styled';
import { facebookURLs } from 'Utils/FacebookURLs';
import { getLinkedinPost } from 'services/rest/Campaign/getLinkedinPost';
import { selectUser } from 'redux/slices/userSlice';
import { useSelector } from 'react-redux';
import { selectCompany } from 'redux/slices/Company/CompanySlice';
import EditorInputLinkedin from 'components/inputs/EditorInput/EditorInputLinkedin';
import Tabs from 'components/Tabs';

const https = 'https://';
const www = '(?:www.)';
const fb = 'facebook.com';
const no_mentions = /^(?:(?!\B@).\n*)+$/;
const no_characters = /^(?!.*[!@#$%^&*()\-_+={}[\]|\\;:'"ª|!· º\\,<.>/?]{5}).+/;

export interface CreateCampaignFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<CampaignData>;
  register: UseFormMethods['register'];
  control: any;
  handleClick?: any;
  handleParticipation: any;
  groupsAmbassador: IGroupAmssador;
  getData?: any;
  handleImage: any;
  handleTypeFile: any;
  campaign: any;
  type: any;
  setImageTwitter: any;
  setImageLinkedin: any;
  setImageInstagram: any;
  setImageWhatsapp: any;
  setImageTiktok: any;
  loader: any;
  setHourt: any;
  setMinutes: any;
  sHour: any;
  sMinutes: any;
  setBackendError: any;
  contentLimits: any;
  invalidLimit: any;
  uploadedTwitter: boolean | null;
  uploadedLinkedin: boolean | null;
  uploadedInstagram: boolean | null;
  uploadedWhatsapp: boolean | null;
  uploadedTiktok: boolean | null;
  linkedinUrn: any;
  setLinkedinUrn: any;
  linkedinUrnError: any;
  setLinkedinUrnError: any;
  checkBox: any;
  setCheckBox: any;
  socialContent: {
    twitter: {
      [key: string]: string;
    };
    linkedin: {
      [key: string]: string;
    };
    tiktok: {
      [key: string]: string;
    };
  };
  setSocialContent: React.Dispatch<
    React.SetStateAction<{
      twitter: {
        [key: string]: string;
      };
      linkedin: {
        [key: string]: string;
      };
      tiktok: {
        [key: string]: string;
      };
    }>
  >;
}

const Option = (
  id: number,
  name: string,
  key: any,
  onChange: any,
  type: string,
  group: Array<Igroup>,
  groupSelect: Array<number>
) => {
  //select default
  const groupselect = groupSelect.find(group => group == id);
  //on select
  const select: Igroup = { id: id, name: name };
  //is check or not
  const selectGroup = group.find(group => group.id == id);
  return (
    <div
      className="checkbok-wrapper"
      key={key}
      id={id.toString()}
      onClick={() => (groupselect ? null : onChange(select, type))}
    >
      <input
        id={id.toString()}
        type="checkbox"
        disabled={!!groupselect}
        onChange={() => onChange(select, type)}
        defaultChecked={!!selectGroup}
        checked={!!selectGroup}
      />
      <Icon
        className="icon-Group"
        name={type == 'group' ? `group` : 'single-user'}
        color="#9b9b9b"
        size="small"
      />
      <label className="label-groups">{name}</label>
    </div>
  );
};

export const userSchema = yup.object().shape({
  name: RequiredString(),
  description: RequiredString()
});

const EditCampaignForm: React.FC<CreateCampaignFormProps> = props => {
  const {
    errors,
    onSubmit,
    register,
    handleClick,
    groupsAmbassador: { groups = [], ambassador = [] },
    getData,
    handleParticipation,
    handleImage,
    handleTypeFile,
    control,
    campaign = {},
    setImageTwitter,
    setImageLinkedin,
    setImageInstagram,
    setImageWhatsapp,
    setImageTiktok,
    loader,
    setHourt,
    setMinutes,
    sHour,
    sMinutes,
    setBackendError,
    contentLimits,
    invalidLimit,
    uploadedTwitter,
    uploadedLinkedin,
    uploadedInstagram,
    uploadedWhatsapp,
    uploadedTiktok,
    linkedinUrn,
    setLinkedinUrn,
    linkedinUrnError,
    setLinkedinUrnError,
    checkBox,
    setCheckBox,
    socialContent,
    setSocialContent
  } = props;

  const { t } = useTranslation(['campaigns_page']);
  const [onShow, setOnShow] = useState(true);
  const refOne = useRef<HTMLDivElement>(null);
  const [onURL, setOnURL] = useState({ URL: '', isInputValid: true });
  const [onTwitter, setOnTwitter] = useState({ URL: '', isInputValid: true });
  const [onTiktok, setOnTiktok] = useState({ URL: '', isInputValid: true });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [checked, setChecked] = useState({
    facebook: campaign?.includesFacebook,
    twitter: campaign?.includesTwitter,
    linkedin: campaign?.includesLinkedin,
    instagram: campaign?.includesInstagram,
    whatsapp: campaign?.includesWhatsapp,
    tiktok: campaign?.includesTiktok
  });
  const { currentUser } = useSelector(selectUser);

  const [file, setFile] = useState({
    twitter: false,
    linkedin: false,
    instagram: false,
    whatsapp: false,
    tiktok: false
  });

  const [type, setType] = useState({
    twitter: campaign.contentPerSocialNetwork?.twitter?.type,
    linkedin: campaign.contentPerSocialNetwork?.linkedin?.type,
    instagram: campaign.contentPerSocialNetwork?.instagram?.type,
    whatsapp: campaign.contentPerSocialNetwork?.whatsapp?.type,
    tiktok: campaign.contentPerSocialNetwork?.tiktok?.type
  });

  const [validationInput, setValidationInput] = useState('');
  const [nameInput, setNameInput] = useState(campaign?.name);
  const [startDateValidation, setStartDateValidation] = useState(false);
  const [endDateValidation, setEndDateValidation] = useState(false);
  const [changeType, setChangeType] = useState({
    twitter: false,
    instagram: false,
    linkedin: false,
    whatsapp: false,
    tiktok: false
  });

  const [currentDate, setCurrentDate] = useState(
    new Date(campaign?.startDateUTC)
  );

  const currentEndDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  );

  const [group, setGroup] = useState<Array<Igroup>>([]);
  const [ambassadors, setAmbassadors] = useState<Array<Igroup>>([]);
  const [isPending, setIsPending] = useState(false);
  const { company } = useSelector(selectCompany);
  const timezone = company?.timezone;

  useEffect(() => {
    setEndDate(new Date(campaign?.finishDate));
    setStartDate(new Date(campaign?.startDateUTC));
    setIsPending(campaign?.status == 'pending');
    if (campaign?.groupsIds?.length > 0) {
      const groups = campaign?.groupsIds.map((groups: any) => {
        return {
          id: groups,
          name: groups
        };
      });
      setGroup(groups);
      setSelectedGroups(groups);
    }

    if (campaign?.ambassadorsIds?.length > 0) {
      const groups = campaign?.ambassadorsIds.map((groups: any) => {
        return {
          id: groups,
          name: groups
        };
      });
      setAmbassadors(groups);
      setSelectedAmbassadors(groups);
    }
  }, []);
  useEffect(() => {
    handleParticipation(group, ambassadors);
  }, [group, ambassadors]);

  useEffect(() => {
    handleTypeFile(type);
  }, [type]);

  useEffect(() => {
    document.addEventListener('click', closeOpenShow, true);
  }, []);

  useEffect(() => {
    getData('');
  }, []);

  const closeOpenShow = (e: any) => {
    if (refOne.current && onShow && !refOne.current.contains(e.target)) {
      setOnShow(true);
    }
  };

  const handlRemoveGroup = (idItem: any) => {
    const newGroup = group.filter(group => group.id != idItem);
    setGroup(newGroup);
  };

  const handlRemoveAmbbassador = (idItem: any) => {
    const newGroup = ambassadors.filter(group => group.id != idItem);
    setAmbassadors(newGroup);
  };

  const handleAddGroup = (select: Igroup, type: string) => {
    switch (type) {
      case 'group':
        if (group.length == 0) {
          setGroup([select]);
        } else {
          const selectGroup = group.find(group => group.id == select.id);
          if (selectGroup) {
            handlRemoveGroup(selectGroup.id);
            break;
          }
          setGroup([...group, select]);
        }
        break;
      case 'ambassadors':
        if (ambassadors.length == 0) {
          setAmbassadors([select]);
        } else {
          const selectGroup = ambassadors.find(group => group.id == select.id);
          if (selectGroup) {
            handlRemoveAmbbassador(selectGroup.id);
            break;
          }
          setAmbassadors([...ambassadors, select]);
        }

        break;
    }
  };

  const hourt = Hourt.map(hourt => {
    return { label: hourt, value: hourt };
  });

  const minutes = Minutes.map(minutes => {
    return { label: minutes, value: minutes };
  });

  const onSelectedTypeFile = (socialNetwork: string, content: boolean) => {
    const socialNetworkSelected = file as any;
    const socialNetworks = {
      ...socialNetworkSelected,
      [socialNetwork]: content
    };
    setFile(socialNetworks);
  };

  const onSelectedType = (socialNetwork: string, typeContent: string) => {
    const socialNetworkSelected = type as any;
    let socialNetworks;
    if (socialNetworkSelected[socialNetwork] == typeContent) {
      socialNetworks = {
        ...socialNetworkSelected,
        [socialNetwork]: 'text'
      };
    } else {
      socialNetworks = {
        ...socialNetworkSelected,
        [socialNetwork]: typeContent
      };
    }
    setType(socialNetworks);
  };

  const onSelectedSocialNetwork = (socialNetwork: string) => {
    const socialNetworkSelected = checked as any;

    const sns = socialNetworkSelected[socialNetwork];
    const socialNetworks = {
      ...socialNetworkSelected,
      [socialNetwork]: !sns
    };
    setChecked(socialNetworks);
    socialNetwork == 'twitter' || socialNetwork == 'linkedin'
      ? onSelectedType(socialNetwork, 'text')
      : '';
  };

  const onSelectCalendar = (startDate: Date, endDate: Date) => {
    if (startDate == null) {
      setStartDate(startDate);
    }

    if (endDate == null) {
      setEndDate(endDate);
    }
    handleClick(startDate, endDate, sHour, sMinutes);
    if (startDateValidation) {
      setBackendError(t('start_date_error'));
    }
  };

  const onChange = (e: any) => {
    getData(e.target.value);
    setOnShow(false);
  };

  const onChangeTypeContent = (content: string, type: string) => {
    onSelectedTypeFile(content, type == 'video');
    onSelectedType(content, type);
    const socialNetworkSelected = file as any;
    const socialNetworks = {
      ...socialNetworkSelected,
      [content]: true
    };
    setChangeType(socialNetworks);
  };

  const onChangeHourtMinute = (hour: any, minutes: any) => {
    setHourt(hour);
    setMinutes(minutes);
  };

  const regextValidation = (URL: string, test: any) => {
    const regex = new RegExp(test);
    return regex.test(URL);
  };

  const getLinkedinReshare = async (event: any) => {
    const { value } = event.target ?? '';
    if (value) {
      const lkResponse = await getLinkedinPost(currentUser?.token, value);
      if (
        lkResponse.data.urnLi &&
        ['share', 'ugcPost'].some(val => lkResponse.data.urnLi.includes(val))
      ) {
        const urnLinkedin = lkResponse.data.urnLi.replace(
          'https://www.linkedin.com/feed/update/',
          ''
        );
        setLinkedinUrn(urnLinkedin);
        setLinkedinUrnError('');
      } else {
        setLinkedinUrn('');
        setLinkedinUrnError(t('error_urn_linkedin'));
      }
    }
  };

  const updateUrlVal = (event: any) => {
    setValidationInput('');
    const { value } = event.target;
    if (!regextValidation(value, https) || !value.startsWith(https)) {
      setValidationInput(t('https_errors'));
      return;
    }

    if (!regextValidation(value, www)) {
      setValidationInput(t('www_errors'));
      return;
    }

    if (!regextValidation(value, fb)) {
      setValidationInput(t('facebook_errors'));
      return;
    }

    if (value.length > 200) {
      setValidationInput(t('count_validation'));
      return;
    }

    const isInputValid = !value || facebookURLs(value);
    setValidationInput('');
    setOnURL({
      URL: value,
      isInputValid
    });
  };

  const updateUrlValTiktok = (event: any) => {
    const isInputValid = !event || regextValidationTiktok(event);

    setOnTiktok({
      URL: event,
      isInputValid
    });
  };

  const updateTwitterField = (event: any) => {
    const isInputValid = !event || regextValidationTwitter(event);
    setOnTwitter({
      URL: event,
      isInputValid
    });
  };

  const regextValidationTwitter = (URL: string) => {
    const regex = new RegExp(no_characters);
    return regex.test(URL);
  };

  const regextValidationTiktok = (URL: string) => {
    const regex = new RegExp(no_mentions);
    return regex.test(URL);
  };

  useEffect(() => {
    if (type.twitter == 'image') {
      setImageTwitter(campaign?.contentPerSocialNetwork?.twitter?.picturesUrls);
    }

    if (type.twitter == 'video') {
      setImageTwitter(campaign?.contentPerSocialNetwork?.twitter?.videoUrl);
    }

    if (type.linkedin == 'image') {
      setImageLinkedin(
        campaign?.contentPerSocialNetwork?.linkedin?.picturesUrls
      );
    }

    if (type.linkedin == 'video') {
      setImageLinkedin(campaign?.contentPerSocialNetwork?.linkedin?.videoUrl);
    }

    if (type.linkedin == 'pdf') {
      setImageLinkedin(campaign?.contentPerSocialNetwork?.linkedin?.pdfUrl);
    }

    if (type.linkedin == 'share') {
      setLinkedinUrn(campaign?.contentPerSocialNetwork?.linkedin?.urnLi);
    }

    if (type.instagram == 'image') {
      setImageInstagram(
        campaign?.contentPerSocialNetwork?.instagram?.picturesUrls
      );
    }

    if (type.instagram == 'video') {
      setImageInstagram(campaign?.contentPerSocialNetwork?.instagram?.videoUrl);
    }

    if (type.whatsapp == 'image') {
      setImageWhatsapp(
        campaign?.contentPerSocialNetwork?.whatsapp?.picturesUrls
      );
    }

    if (type.whatsapp == 'video') {
      setImageWhatsapp(campaign?.contentPerSocialNetwork?.whatsapp?.videoUrl);
    }

    if (type.tiktok == 'video') {
      setImageTiktok(campaign?.contentPerSocialNetwork?.tiktok?.videoUrl);
    }
  }, []);

  const [selectedGroups, setSelectedGroups] = useState(campaign?.groupsIds);
  const [selectedAmbassadors, setSelectedAmbassadors] = useState(
    campaign?.ambassadorsIds
  );

  const selectedGroupsOptions: any = [];
  const remainingGroupsOptions: any = [];
  groups.forEach(option => {
    if (
      Array.isArray(selectedGroups) &&
      selectedGroups.some((selected: any) => selected.id === option.id)
    ) {
      selectedGroupsOptions.push(option);
    } else {
      remainingGroupsOptions.push(option);
    }
  });
  const selectedAmbassadorsOptions: any = [];
  const remainingAmbassadorsOptions: any = [];
  if (Array.isArray(selectedAmbassadors)) {
    ambassador.forEach(option => {
      if (
        selectedAmbassadors.some((selected: any) => selected.id === option.id)
      ) {
        selectedAmbassadorsOptions.push(option);
      } else {
        remainingAmbassadorsOptions.push(option);
      }
    });
  }

  const showOptionsSelected =
    campaign?.status === 'activated' || campaign?.status === 'completed';

  const showOptionsToEdit =
    campaign?.status === 'pending' || campaign?.status === 'paused';

  const pathName = new URLSearchParams(window.location.search);
  let newPath = '';

  if (pathName.has('All')) {
    newPath = '/campaigns/admin/campaignsAll';
  }

  const firstName = (e: any) => {
    const text = e.target.value;
    setNameInput(text.charAt(0).toUpperCase() + text.slice(1));
  };

  const open = (urnLi: string) => {
    window.open(
      `https://www.linkedin.com/feed/update/${urnLi}`,
      '_blank',
      'noreferrer'
    );
  };

  const [socialContentTab, setSocialContentTab] = useState({
    twitter: 0,
    linkedin: 0,
    tiktok: 0
  });

  const addSocialContent = (social: 'twitter' | 'linkedin' | 'tiktok') => {
    const keys = Object.keys(socialContent[social]);
    if (keys.length > 2) {
      return;
    }

    setSocialContent(prev => ({
      ...prev,
      [social]: {
        ...prev[social],
        [`content${keys.length + 1}`]: ''
      }
    }));
  };

  const removeSocialContent = (social: 'linkedin' | 'twitter' | 'tiktok') => {
    const keys = Object.keys(socialContent[social]);
    const lastIndex = keys.length;
    const socialContentCopy = { ...socialContent[social] };

    if (lastIndex - 2 >= 0) {
      setSocialContentTab(prev => ({ ...prev, [social]: lastIndex - 2 }));
    }

    if (lastIndex === 1) {
      return;
    }

    delete socialContentCopy[`content${lastIndex}`];

    setSocialContent(prev => ({
      ...prev,
      [social]: socialContentCopy
    }));
  };

  const handleChangeSocialContent = (
    value: string,
    index: number,
    social: 'linkedin' | 'twitter' | 'tiktok'
  ) => {
    setSocialContent(prev => ({
      ...prev,
      [social]: {
        ...socialContent[social],
        [`content${index === 0 ? '' : index + 1}`]: value
      }
    }));
  };

  return (
    <SectionWrapper className="hide-on-mobile">
      <NavigationLink
        text={t('back')}
        direction="back"
        to={newPath || '/admin/campaigns'}
      />
      <h1>{t('edit_campaign')}</h1>
      <NavigationLink
        text={t('campaign_metrics')}
        direction="forward"
        to={`/campaigns/admin/detail/${campaign?.id}`}
      />
      <form onSubmit={onSubmit}>
        <FormWrapper>
          <InputsWrapper>
            <LabelForm>
              <div>
                <label>{t('name_campaign')}</label>
              </div>
              <div>
                <Input
                  type="text"
                  name="name"
                  value={nameInput}
                  ref={register}
                  error={errors.name?.message}
                  data-testid="name"
                  disabled={
                    !!(
                      campaign?.status === 'completed' ||
                      campaign?.status === 'activated'
                    )
                  }
                  max={150}
                  maxLength={150}
                  onChange={e => {
                    firstName(e);
                  }}
                />
              </div>
              <div className="marginFile">
                <UploadFileCropper
                  icon="photo"
                  name="photo"
                  handleImage={handleImage}
                  id="content-photo"
                  imagesArray={[campaign.photo]}
                  maxFileSize={5000}
                  ref={register}
                  infoBox={t('info_box_photo_content')}
                  disabled={
                    !!(
                      campaign?.status === 'completed' ||
                      campaign?.status === 'activated'
                    )
                  }
                />
              </div>
            </LabelForm>
            <LabelForm>
              <div>
                <label>{t('description_campaign')}</label>
              </div>
              <div>
                <TextArea
                  name="description"
                  ref={register}
                  error={errors.description?.message}
                  defaultValue={campaign.description}
                  rows={8}
                  maxLength={500}
                  placeholder={t('suggestion_description')}
                  disabled={
                    !!(
                      campaign?.status === 'completed' ||
                      campaign?.status === 'activated'
                    )
                  }
                />
              </div>
            </LabelForm>
            <LabelForm>
              <div>
                <label>{t('date_campaign')}</label>
                <p>{t('suggestion_date')}</p>
              </div>
              <GridComponent>
                <FlexSection className="notranslate">
                  <Icon size="big" name="custom-calendar" color="#9b9b9b" />
                  <Calendar
                    onChange={onSelectCalendar}
                    startDate={currentDate}
                    endDate={currentEndDate}
                    defaultStartDate={startDate}
                    defaultEndDate={endDate}
                    editMode={true}
                    setStartDateValidation={setStartDateValidation}
                    startDateValidation={startDateValidation}
                    setEndDateValidation={setEndDateValidation}
                    endDateValidation={endDateValidation}
                    withinSameMonth={!checkBox}
                    isPending={isPending}
                    editStarDate={campaign?.status === 'pending'}
                  />
                </FlexSection>
                <FlexSection
                  disabled={
                    campaign?.status === 'completed' ||
                    campaign?.status === 'activated'
                  }
                >
                  <ReactSelect
                    options={hourt}
                    defaultValue={
                      hourt[
                        currentDate.getHours() != 0 ? currentDate.getHours() : 0
                      ]
                    }
                    isDisabled={campaign?.status === 'paused'}
                    onChange={e => onChangeHourtMinute(e?.value, sMinutes)}
                    name="hourtCampaign"
                    className="dropdown-filter-container"
                    classNamePrefix="dropdown-filter-content"
                  />
                  <span id="spanPoint">:</span>
                  <ReactSelect
                    name="hourtCampaign"
                    options={minutes}
                    isDisabled={campaign?.status === 'paused'}
                    onChange={e => onChangeHourtMinute(sHour, e?.value)}
                    defaultValue={
                      minutes[
                        currentDate.getMinutes() != 0
                          ? currentDate.getMinutes()
                          : 0
                      ]
                    }
                    className="dropdown-filter-container"
                    classNamePrefix="dropdown-filter-content"
                  />
                  <TimeZoneCampaign>
                    <label>
                      <b>{t('timezone_campaign')}</b>
                    </label>
                    <label>{timezone}</label>
                  </TimeZoneCampaign>
                </FlexSection>
                <FlexSection>
                  <StyledCheckBoxTerms>
                    <Input
                      id="extraordinal"
                      checked={checkBox}
                      name="allowExtraDates"
                      type="checkbox"
                      ref={register}
                      disabled={
                        campaign?.status === 'completed' ||
                        campaign?.status === 'paused'
                      }
                      onClick={() => {
                        setCheckBox(!checkBox);
                        setStartDate(new Date());
                        setEndDate(new Date());
                        setCurrentDate(new Date());
                      }}
                    />
                    <label>
                      <p>{t('activate_extraordinary')}</p>
                    </label>
                  </StyledCheckBoxTerms>
                </FlexSection>
              </GridComponent>
            </LabelForm>
            <LabelForm>
              <div>
                <label>{t('participants_campaign')}</label>
                <p>{t('suggestion_participants')}</p>
              </div>

              <div ref={refOne}>
                <input
                  type="search"
                  name="searcher"
                  autoComplete="off"
                  placeholder={
                    group.length > 0 || ambassadors.length > 0
                      ? `Grupos +${group.length} /Personas +${ambassadors.length}`
                      : 'Buscar grupos o personas'
                  }
                  className={
                    group.length > 0 || ambassadors.length > 0
                      ? `input-search selected`
                      : 'input-search'
                  }
                  onClick={() => {
                    setOnShow(!onShow);
                  }}
                  onChange={e => onChange(e)}
                />
                {onShow ? (
                  <></>
                ) : (
                  <div className="search-containe notranslate">
                    {showOptionsSelected && (
                      <div className="search-result notranslate">
                        {selectedGroupsOptions.map((groups: any, key: any) =>
                          Option(
                            groups.id,
                            groups.name,
                            key,
                            handleAddGroup,
                            'group',
                            group,
                            campaign?.groupsIds
                          )
                        )}
                        {selectedAmbassadorsOptions.map(
                          (ambassador: any, key: any) =>
                            Option(
                              ambassador.id,
                              `${ambassador.name} ${ambassador.lastName}`,
                              key,
                              handleAddGroup,
                              'ambassadors',
                              ambassadors,
                              campaign?.ambassadorsIds
                            )
                        )}
                      </div>
                    )}
                    {showOptionsToEdit && (
                      <div className="search-result notranslate">
                        {selectedGroupsOptions.map((groups: any, key: any) =>
                          Option(
                            groups.id,
                            groups.name,
                            key,
                            handleAddGroup,
                            'group',
                            group,
                            campaign?.groupsIds
                          )
                        )}
                        {selectedAmbassadorsOptions.map(
                          (ambassador: any, key: any) =>
                            Option(
                              ambassador.id,
                              `${ambassador.name} ${ambassador.lastName}`,
                              key,
                              handleAddGroup,
                              'ambassadors',
                              ambassadors,
                              campaign?.ambassadorsIds
                            )
                        )}
                        {remainingGroupsOptions.map((groups: any, key: any) =>
                          Option(
                            groups.id,
                            groups.name,
                            key,
                            handleAddGroup,
                            'group',
                            group,
                            campaign?.groupsIds
                          )
                        )}
                        {remainingAmbassadorsOptions.map(
                          (ambassador: any, key: any) =>
                            Option(
                              ambassador.id,
                              `${ambassador.name} ${ambassador.lastName}`,
                              key,
                              handleAddGroup,
                              'ambassadors',
                              ambassadors,
                              campaign?.ambassadorsIds
                            )
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </LabelForm>
            <LabelForm>
              <div>
                <label>{t('social_networks_c')}</label>
                <p>{t('suggestion_social_networks')}</p>
              </div>
              <div className="network">
                {/*<NetworkFlexContainer>
                  <Icon size="small" name="facebook" color={Colors.facebook} />
                  <Input
                    id="sna_facebook"
                    type="checkbox"
                    name="includesFacebook"
                    ref={register}
                    disabled={
                      campaign?.status === 'completed' ||
                      campaign?.status === 'activated' ||
                      campaign.includesFacebook
                    }
                    checked={checked.facebook}
                    onChange={() => onSelectedSocialNetwork('facebook')}
                    error={errors.includesFacebook?.message}
                  />
                </NetworkFlexContainer>*/}
                <NetworkFlexContainer>
                  <Icon size="small" name="twitter" color={Colors.twitter} />
                  <Input
                    id="sna_twitter"
                    type="checkbox"
                    name="includesTwitter"
                    disabled={
                      campaign?.status === 'completed' ||
                      campaign?.status === 'activated' ||
                      campaign.includesTwitter
                    }
                    ref={register}
                    checked={checked.twitter}
                    onChange={() => onSelectedSocialNetwork('twitter')}
                  />
                </NetworkFlexContainer>
                <NetworkFlexContainer>
                  <Icon size="small" name="linkedin" color={Colors.linkedin} />
                  <Input
                    id="sna_linkedin"
                    type="checkbox"
                    name="includesLinkedin"
                    ref={register}
                    disabled={
                      campaign?.status === 'completed' ||
                      campaign?.status === 'activated' ||
                      campaign.includesLinkedin
                    }
                    checked={checked.linkedin}
                    onChange={() => onSelectedSocialNetwork('linkedin')}
                  />
                </NetworkFlexContainer>
                <NetworkFlexContainer>
                  <Icon
                    size="small"
                    name="instagram"
                    color={Colors.instagram}
                  />
                  <Input
                    id="sna_instagram"
                    type="checkbox"
                    disabled={
                      campaign?.status === 'completed' ||
                      campaign?.status === 'activated' ||
                      campaign.includesInstagram
                    }
                    name="includesInstagram"
                    ref={register}
                    checked={checked.instagram}
                    onChange={() => onSelectedSocialNetwork('instagram')}
                  />
                </NetworkFlexContainer>
                <NetworkFlexContainer>
                  <Icon size="small" name="whatsapp" color={Colors.whatsapp} />
                  <Input
                    id="sna_whatsapp"
                    type="checkbox"
                    disabled={
                      campaign?.status === 'completed' ||
                      campaign?.status === 'activated' ||
                      campaign.includesWhatsapp
                    }
                    name="includesWhatsapp"
                    ref={register}
                    checked={checked.whatsapp}
                    onChange={() => onSelectedSocialNetwork('whatsapp')}
                  />
                </NetworkFlexContainer>
                <NetworkFlexContainer>
                  <Icon size="small" name="tiktok" color={Colors.tiktok} />
                  <Input
                    id="sna_tiktok"
                    type="checkbox"
                    disabled={
                      campaign?.status === 'completed' ||
                      campaign?.status === 'activated' ||
                      campaign.includesTiktok
                    }
                    name="includesTiktok"
                    ref={register}
                    checked={checked.tiktok}
                    onChange={() => onSelectedSocialNetwork('tiktok')}
                  />
                </NetworkFlexContainer>
              </div>
            </LabelForm>
            <LabelForm>
              <div className="marginLabel">
                <label>{t('score_campaign')}</label>
                <p>{t('suggestion_score')}</p>
              </div>
              <div>
                {checked.facebook && (
                  <div className="marginColumn">
                    <Icon
                      size="small"
                      name="facebook"
                      color={Colors.facebook}
                    />
                    <Column>
                      <NumberWrapper>
                        <label>{t('post_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesFacebook
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.facebook?.publish !==
                            'undefined'
                              ? campaign.pointsValue?.facebook?.publish
                              : 10
                          }
                          name="facebook.publish"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('share_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesFacebook
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.facebook?.shared !==
                            'undefined'
                              ? campaign.pointsValue?.facebook?.shared
                              : 3
                          }
                          name="facebook.shared"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('comment_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesFacebook
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.facebook?.comments !==
                            'undefined'
                              ? campaign.pointsValue?.facebook?.comments
                              : 2
                          }
                          name="facebook.comments"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('reaction_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesFacebook
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.facebook?.reactions !==
                            'undefined'
                              ? campaign.pointsValue?.facebook?.reactions
                              : 1
                          }
                          name="facebook.reactions"
                          ref={register}
                        />
                      </NumberWrapper>
                    </Column>
                  </div>
                )}
                {checked.twitter && (
                  <div className="marginColumn">
                    <Icon size="small" name="twitter" color={Colors.twitter} />
                    <Column>
                      <NumberWrapper>
                        <label>{t('post_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesTwitter
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.twitter?.publish !==
                            'undefined'
                              ? campaign.pointsValue?.twitter?.publish
                              : 10
                          }
                          name="twitter.publish"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('retweet_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesTwitter
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.twitter?.shared !==
                            'undefined'
                              ? campaign.pointsValue?.twitter?.shared
                              : 2
                          }
                          name="twitter.shared"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('reply_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesTwitter
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.twitter?.comments !==
                            'undefined'
                              ? campaign.pointsValue?.twitter?.comments
                              : 2
                          }
                          name="twitter.comments"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('like_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesTwitter
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.twitter?.reactions !==
                            'undefined'
                              ? campaign.pointsValue?.twitter?.reactions
                              : 1
                          }
                          name="twitter.reactions"
                          ref={register}
                        />
                      </NumberWrapper>
                    </Column>
                  </div>
                )}
                {checked.linkedin && (
                  <div className="marginColumn">
                    <Icon
                      size="small"
                      name="linkedin"
                      color={Colors.linkedin}
                    />
                    <Column>
                      <NumberWrapper>
                        <label>{t('post_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesLinkedin
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.linkedin?.publish !==
                            'undefined'
                              ? campaign.pointsValue?.linkedin?.publish
                              : 10
                          }
                          name="linkedin.publish"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('comment_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesLinkedin
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.linkedin?.comments !==
                            'undefined'
                              ? campaign.pointsValue?.linkedin?.comments
                              : 2
                          }
                          name="linkedin.comments"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('reactions_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesLinkedin
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.linkedin?.reactions !==
                            'undefined'
                              ? campaign.pointsValue?.linkedin?.reactions
                              : 1
                          }
                          name="linkedin.reactions"
                          ref={register}
                        />
                      </NumberWrapper>
                    </Column>
                  </div>
                )}

                {checked.instagram && (
                  <div className="marginColumn">
                    <Icon
                      size="small"
                      name="instagram"
                      color={Colors.instagram}
                    />
                    <Column>
                      <NumberWrapper>
                        <label>{t('post_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesInstagram
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.instagram?.publish !==
                            'undefined'
                              ? campaign.pointsValue?.instagram?.publish
                              : 10
                          }
                          name="instagram.publish"
                          ref={register}
                        />
                      </NumberWrapper>
                    </Column>
                  </div>
                )}

                {checked.whatsapp && (
                  <div className="marginColumn">
                    <Icon
                      size="small"
                      name="whatsapp"
                      color={Colors.whatsapp}
                    />
                    <Column>
                      <NumberWrapper>
                        <label>{t('post_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesWhatsapp
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.whatsapp?.publish !==
                            'undefined'
                              ? campaign.pointsValue?.whatsapp?.publish
                              : 10
                          }
                          name="whatsapp.publish"
                          ref={register}
                        />
                      </NumberWrapper>
                    </Column>
                  </div>
                )}
                {checked.tiktok && (
                  <div className="marginColumn">
                    <Icon size="small" name="tiktok" color={Colors.tiktok} />
                    <Column>
                      <NumberWrapper>
                        <label>{t('post_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesTiktok
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.tiktok?.publish !==
                            'undefined'
                              ? campaign.pointsValue?.tiktok?.publish
                              : 10
                          }
                          name="tiktok.publish"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('share_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesTiktok
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.tiktok?.shared !==
                            'undefined'
                              ? campaign.pointsValue?.tiktok?.shared
                              : 3
                          }
                          name="tiktok.shared"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('comment_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesTiktok
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.tiktok?.comments !==
                            'undefined'
                              ? campaign.pointsValue?.tiktok?.comments
                              : 2
                          }
                          name="tiktok.comments"
                          ref={register}
                        />
                      </NumberWrapper>
                      <NumberWrapper>
                        <label>{t('like_title')}</label>
                        <Input
                          type="number"
                          min={0}
                          max={999}
                          maxLength={999}
                          className={
                            campaign?.status !== 'pending' &&
                            campaign.includesTiktok
                              ? 'disabled-field'
                              : ''
                          }
                          defaultValue={
                            typeof campaign.pointsValue?.tiktok?.reactions !==
                            'undefined'
                              ? campaign.pointsValue?.tiktok?.reactions
                              : 1
                          }
                          name="tiktok.reactions"
                          ref={register}
                        />
                      </NumberWrapper>
                    </Column>
                  </div>
                )}

                <div className="borde" />
              </div>
            </LabelForm>
            <FormTextArea>
              <div className="marginLabel">
                <label>{t('contents_sn')}</label>
                <p>{t('suggestion_content')}</p>
              </div>
              <div>
                {checked.facebook && (
                  <SocialNetworkContent>
                    <div>
                      <Icon
                        size="small"
                        name="facebook"
                        color={Colors.facebook}
                      />
                    </div>
                    <label>{t('url_post')}</label>
                    <Input
                      name="contentFacebook"
                      ref={register({
                        required: checked.facebook
                      })}
                      data-testid="name"
                      onChange={updateUrlVal}
                      defaultValue={
                        campaign.contentPerSocialNetwork?.facebook?.content
                      }
                      error={
                        errors.contentFacebook?.type === 'required'
                          ? t('required_field')
                          : ''
                      }
                      type="text"
                      placeholder={t('place_holder_fb')}
                      disabled={
                        !!(
                          campaign?.status === 'completed' ||
                          campaign?.status === 'activated'
                        )
                      }
                    />
                    {validationInput && (
                      <ErrorSpan>{validationInput}</ErrorSpan>
                    )}
                    {(!onURL.isInputValid ||
                      errors.contentFacebook?.type == 'pattern') && (
                      <ErrorSpan>{t('error_url')}</ErrorSpan>
                    )}
                  </SocialNetworkContent>
                )}
                {checked.twitter && (
                  <SocialNetworkContent className="with-file">
                    <div className="SocialNetworkBox">
                      <div>
                        <Icon
                          size="small"
                          name="twitter"
                          color={Colors.twitter}
                        />
                      </div>
                      <label>{t('description_tweet')}</label>
                      <Tabs
                        className="multiple-contents"
                        orientation="horizontal"
                        tabsTitle={Object.keys(socialContent.twitter).map(
                          (content, index) => ({
                            id: index + 1,
                            label: `${t('campaigns_page:option')} ${index + 1}`
                          })
                        )}
                        tab={socialContentTab.twitter}
                        tabsContent={Object.keys(socialContent.twitter).map(
                          (content, index) => ({
                            id: index + 1,
                            children: (
                              <div className="MarginEditText">
                                <Controller
                                  name={`contentTwitter${
                                    index === 0 ? '' : `${index + 1}`
                                  }`}
                                  control={control}
                                  rules={
                                    !socialContent.twitter[
                                      `content${
                                        index === 0 ? '' : `${index + 1}`
                                      }`
                                    ]
                                      ? {
                                          required: checked.twitter,
                                          maxLength: contentLimits.twitter,
                                          pattern: no_characters
                                        }
                                      : {
                                          required: false,
                                          maxLength: contentLimits.twitter,
                                          pattern: no_characters
                                        }
                                  }
                                  disabled={
                                    !!(
                                      campaign?.status === 'completed' ||
                                      campaign?.status === 'activated'
                                    )
                                  }
                                  defaultValue={
                                    socialContent.twitter[
                                      `content${
                                        index === 0 ? '' : `${index + 1}`
                                      }`
                                    ]
                                  }
                                  render={({
                                    value = socialContent.twitter[
                                      `content${
                                        index === 0 ? '' : `${index + 1}`
                                      }`
                                    ],
                                    onChange,
                                    ref
                                  }) => (
                                    <EditorInput
                                      isTwitter={true}
                                      ref={ref}
                                      className="content-editor"
                                      placeholder=""
                                      maxCharacters={contentLimits.twitter}
                                      content={value}
                                      onChange={e => {
                                        onChange(e);
                                        updateTwitterField(e);
                                        handleChangeSocialContent(
                                          e,
                                          index,
                                          'twitter'
                                        );
                                      }}
                                      disabled={
                                        !!(
                                          campaign?.status === 'completed' ||
                                          campaign?.status === 'activated'
                                        )
                                      }
                                    />
                                  )}
                                />
                              </div>
                            )
                          })
                        )}
                      />

                      <div className="styled_border">
                        <Icon
                          className={
                            type.twitter == 'image' || type.twitter == 'text'
                              ? 'icon-tw-active'
                              : 'icon-tw-noactive'
                          }
                          size="medium"
                          name="photo"
                          color={Colors.twitter}
                          onClick={() =>
                            campaign?.status !== 'completed' &&
                            onChangeTypeContent('twitter', 'image')
                          }
                        />
                        <Icon
                          className={
                            type.twitter == 'video' || type.twitter == 'text'
                              ? 'icon-tw-active'
                              : 'icon-tw-noactive'
                          }
                          size="medium"
                          name="video"
                          color={Colors.twitter}
                          onClick={() =>
                            campaign?.status !== 'completed' &&
                            onChangeTypeContent('twitter', 'video')
                          }
                        />
                        <div className="tabs-controls">
                          {Object.keys(socialContent.twitter).length < 3 && (
                            <Icon
                              className={'icon-ln-active'}
                              size="medium"
                              name="add"
                              color={Colors.twitter}
                              onClick={() => addSocialContent('twitter')}
                            />
                          )}
                          {Object.keys(socialContent.twitter).length > 1 && (
                            <Icon
                              className={'icon-ln-noactive'}
                              size="medium"
                              name="less"
                              color={Colors.twitter}
                              onClick={() => removeSocialContent('twitter')}
                            />
                          )}
                        </div>
                      </div>
                      {errors.contentTwitter?.type === 'required' && (
                        <ErrorSpan>{t('required_field')}</ErrorSpan>
                      )}
                      {(errors.contentTwitter?.type === 'pattern' ||
                        !onTwitter.isInputValid) && (
                        <ErrorSpan>{t('characters')}</ErrorSpan>
                      )}
                      {invalidLimit.twitter && (
                        <ErrorSpan>{t('error_limit')}</ErrorSpan>
                      )}
                    </div>
                    <div className="marginFile">
                      {type.twitter == 'video' && (
                        <UploadFileVideo
                          icon="video"
                          name="contentPerSocialNetwork.twitter"
                          handleVideo={handleImage}
                          className="socialNetworkFile"
                          content="twitter"
                          ref={register}
                          infoBox={t('info_box_video_twitter')}
                          uploaded={uploadedTwitter}
                          videosArray={
                            changeType.twitter
                              ? []
                              : [
                                  campaign.contentPerSocialNetwork?.twitter
                                    ?.videoUrl
                                ]
                          }
                          disabled={
                            !!(
                              campaign?.status === 'completed' ||
                              campaign?.status === 'activated'
                            )
                          }
                        />
                      )}
                      {type.twitter == 'image' && (
                        <UploadFileMultipleImage
                          uploaded={uploadedTwitter}
                          icon="photo"
                          name="contentPerSocialNetwork.twitter"
                          handleImage={handleImage}
                          className="socialNetworkFile"
                          content="twitter"
                          ref={register}
                          infoBox={t('info_box_photo_twitter')}
                          imagesArray={
                            campaign.contentPerSocialNetwork?.twitter?.type ==
                            'image'
                              ? campaign.contentPerSocialNetwork?.twitter
                                  ?.picturesUrls
                              : []
                          }
                          disabled={
                            !!(
                              campaign?.status === 'completed' ||
                              campaign?.status === 'activated'
                            )
                          }
                        />
                      )}
                    </div>
                  </SocialNetworkContent>
                )}
                {checked.linkedin && (
                  <SocialNetworkContent
                    className="with-file"
                    disabled={campaign?.status === 'completed'}
                  >
                    <div className="SocialNetworkBox">
                      <div>
                        <Icon
                          size="small"
                          name="linkedin"
                          color={Colors.linkedin}
                        />
                      </div>
                      <label>{t('description_post_ln')}</label>
                      <Tabs
                        className="multiple-contents"
                        orientation="horizontal"
                        tabsTitle={Object.keys(socialContent.linkedin).map(
                          (content, index) => ({
                            id: index + 1,
                            label: `${t('campaigns_page:option')} ${index + 1}`
                          })
                        )}
                        tab={socialContentTab.linkedin}
                        tabsContent={Object.keys(socialContent.linkedin).map(
                          (content, index) => ({
                            id: index + 1,
                            children: (
                              <div className="MarginEditText">
                                <Controller
                                  name={`contentLinkedin${
                                    index === 0 ? '' : `${index + 1}`
                                  }`}
                                  control={control}
                                  rules={
                                    !socialContent.linkedin[
                                      `content${
                                        index === 0 ? '' : `${index + 1}`
                                      }`
                                    ]
                                      ? {
                                          required: checked.linkedin,
                                          pattern: no_mentions,
                                          maxLength: contentLimits.linkedin
                                        }
                                      : {
                                          required: false,
                                          maxLength: contentLimits.linkedin
                                        }
                                  }
                                  disabled={
                                    !!(
                                      campaign?.status === 'completed' ||
                                      campaign?.status === 'activated'
                                    )
                                  }
                                  defaultValue={
                                    socialContent.linkedin[
                                      `content${
                                        index === 0 ? '' : `${index + 1}`
                                      }`
                                    ]
                                  }
                                  render={({
                                    value = socialContent.linkedin[
                                      `content${
                                        index === 0 ? '' : `${index + 1}`
                                      }`
                                    ],
                                    onChange,
                                    ref
                                  }) => (
                                    <EditorInputLinkedin
                                      isTwitter={false}
                                      ref={ref}
                                      className="content-editor"
                                      placeholder=""
                                      maxCharacters={contentLimits.linkedin}
                                      content={value}
                                      onChange={e => {
                                        onChange(e);
                                        handleChangeSocialContent(
                                          e,
                                          index,
                                          'linkedin'
                                        );
                                      }}
                                      disabled={
                                        !!(
                                          campaign?.status === 'completed' ||
                                          campaign?.status === 'activated'
                                        )
                                      }
                                    />
                                  )}
                                />
                              </div>
                            )
                          })
                        )}
                      />

                      <div className="styled_border">
                        <Icon
                          className={
                            type.linkedin == 'image' || type.linkedin == 'text'
                              ? 'icon-ln-active'
                              : 'icon-ln-noactive'
                          }
                          size="medium"
                          name="photo"
                          color={Colors.twitter}
                          onClick={() =>
                            campaign?.status !== 'completed' &&
                            onChangeTypeContent('linkedin', 'image')
                          }
                        />
                        <Icon
                          className={
                            type.linkedin == 'video' || type.linkedin == 'text'
                              ? 'icon-ln-active'
                              : 'icon-ln-noactive'
                          }
                          size="medium"
                          name="video"
                          color={Colors.twitter}
                          onClick={() =>
                            campaign?.status !== 'completed' &&
                            onChangeTypeContent('linkedin', 'video')
                          }
                        />
                        <Icon
                          className={
                            type.linkedin == 'pdf' || type.linkedin == 'text'
                              ? 'icon-ln-active'
                              : 'icon-ln-noactive'
                          }
                          size="medium"
                          name="pdf"
                          color={Colors.twitter}
                          onClick={() =>
                            campaign?.status !== 'completed' &&
                            onChangeTypeContent('linkedin', 'pdf')
                          }
                        />
                        {Object.keys(socialContent.linkedin).length == 1 && (
                          <Icon
                            className={
                              type.linkedin == 'share' ||
                              type.linkedin == 'text'
                                ? 'icon-ln-active'
                                : 'icon-ln-noactive'
                            }
                            size="medium"
                            name="link"
                            color={Colors.twitter}
                            onClick={() =>
                              onChangeTypeContent('linkedin', 'share')
                            }
                          />
                        )}
                        {type.linkedin != 'share' && (
                          <div className="tabs-controls">
                            {Object.keys(socialContent.linkedin).length < 3 && (
                              <Icon
                                className={'icon-ln-active'}
                                size="medium"
                                name="add"
                                color={Colors.twitter}
                                onClick={() => addSocialContent('linkedin')}
                              />
                            )}
                            {Object.keys(socialContent.linkedin).length > 1 && (
                              <Icon
                                className={'icon-ln-noactive'}
                                size="medium"
                                name="less"
                                color={Colors.twitter}
                                onClick={() => removeSocialContent('linkedin')}
                              />
                            )}
                          </div>
                        )}
                      </div>
                      {type.linkedin == 'share' && (
                        <>
                          <label>{t('url_post')}</label>
                          {linkedinUrn && (
                            <Icon
                              className="open"
                              size="small"
                              name="eye"
                              onClick={() => open(linkedinUrn)}
                              color="#0077b5"
                            />
                          )}
                          <Input
                            name="contentLinkedinShare"
                            ref={register({
                              required: checked.linkedin
                            })}
                            data-testid="name"
                            onChange={getLinkedinReshare}
                            error={
                              errors.contentLinkedin?.type === 'required'
                                ? t('required_field')
                                : ''
                            }
                            type="text"
                            placeholder={t('placeholder_lk')}
                            defaultValue={
                              campaign.contentPerSocialNetwork?.linkedin?.url
                            }
                            disabled={
                              !!(
                                campaign?.status === 'completed' ||
                                campaign?.status === 'activated'
                              )
                            }
                          />
                        </>
                      )}
                      {errors.contentLinkedin?.type === 'required' && (
                        <ErrorSpan>{t('required_field')}</ErrorSpan>
                      )}
                      {invalidLimit.linkedin && (
                        <ErrorSpan>{t('error_limit')}</ErrorSpan>
                      )}
                      {type.linkedin == 'share' && linkedinUrnError && (
                        <ErrorSpan>{linkedinUrnError}</ErrorSpan>
                      )}
                    </div>
                    <div className="marginFile">
                      {type.linkedin == 'pdf' && (
                        <UploadFileImageSimple
                          icon="pdf"
                          name="contentPerSocialNetwork.linkedin"
                          handleImage={handleImage}
                          className="socialNetworkFile"
                          content="linkedin"
                          maxFileSize={10000}
                          ref={register}
                          infoBox={t('info_box_pdf_linkedin')}
                          allowedFileTypes="application/pdf"
                          imagesArray={
                            campaign.contentPerSocialNetwork?.linkedin?.type ==
                            'pdf'
                              ? [
                                  campaign.contentPerSocialNetwork?.linkedin
                                    ?.pdfUrl
                                ]
                              : []
                          }
                          disabled={
                            !!(
                              campaign?.status === 'completed' ||
                              campaign?.status === 'activated'
                            )
                          }
                          openNewTab={true}
                        />
                      )}
                      {type.linkedin == 'video' && (
                        <UploadFileVideo
                          icon="video"
                          name="contentPerSocialNetwork.linkedin"
                          handleVideo={handleImage}
                          className="socialNetworkFile"
                          content="linkedin"
                          ref={register}
                          maxFileSize={200000}
                          maxFileDuration={600}
                          infoBox={t('info_box_video_linkedin')}
                          uploaded={uploadedLinkedin}
                          videosArray={
                            changeType.linkedin
                              ? []
                              : [
                                  campaign.contentPerSocialNetwork?.linkedin
                                    ?.videoUrl
                                ]
                          }
                          disabled={
                            !!(
                              campaign?.status === 'completed' ||
                              campaign?.status === 'activated'
                            )
                          }
                        />
                      )}
                      {type.linkedin == 'image' && (
                        <UploadFileMultipleImage
                          uploaded={uploadedLinkedin}
                          icon="photo"
                          name="contentPerSocialNetwork.linkedin"
                          handleImage={handleImage}
                          className="socialNetworkFile"
                          content="linkedin"
                          ref={register}
                          infoBox={t('info_box_photo_linkedin')}
                          imagesArray={
                            campaign.contentPerSocialNetwork?.linkedin?.type ==
                            'image'
                              ? campaign.contentPerSocialNetwork?.linkedin
                                  ?.picturesUrls
                              : []
                          }
                          disabled={
                            !!(
                              campaign?.status === 'completed' ||
                              campaign?.status === 'activated'
                            )
                          }
                        />
                      )}
                    </div>
                  </SocialNetworkContent>
                )}
                {checked.instagram && (
                  <SocialNetworkContent className="with-file">
                    <div className="SocialNetworkBox">
                      <div>
                        <Icon
                          size="small"
                          name="instagram"
                          color={Colors.instagram}
                        />
                      </div>
                      <label>{t('description_post')}</label>
                      <div className="MarginEditText">
                        <Controller
                          name="contentInstagram"
                          control={control}
                          rules={{
                            maxLength: contentLimits.instagram
                          }}
                          defaultValue={
                            campaign.contentPerSocialNetwork?.instagram?.content
                          }
                          render={({
                            value = campaign.contentPerSocialNetwork?.instagram
                              ?.content,
                            onChange,
                            ref
                          }) => (
                            <EditorInput
                              isTwitter={false}
                              ref={ref}
                              className="content-editor"
                              placeholder=""
                              maxCharacters={contentLimits.instagram}
                              content={value}
                              onChange={e => {
                                onChange(e);
                              }}
                              disabled={
                                !!(
                                  campaign?.status === 'completed' ||
                                  campaign?.status === 'activated'
                                )
                              }
                            />
                          )}
                        />
                      </div>
                      <div className="styled_border">
                        <Icon
                          className={
                            type.instagram == 'image' ||
                            type.instagram == 'text'
                              ? 'icon-ins-active'
                              : 'icon-ins-noactive'
                          }
                          size="medium"
                          name="photo"
                          color={Colors.twitter}
                          onClick={() =>
                            campaign?.status !== 'completed' &&
                            onChangeTypeContent('instagram', 'image')
                          }
                        />
                        <Icon
                          className={
                            type.instagram == 'video' ||
                            type.instagram == 'text'
                              ? 'icon-ins-active'
                              : 'icon-ins-noactive'
                          }
                          size="medium"
                          name="video"
                          color={Colors.twitter}
                          onClick={() =>
                            campaign?.status !== 'completed' &&
                            onChangeTypeContent('instagram', 'video')
                          }
                        />
                      </div>
                      {errors.contentInstagram?.type === 'required' && (
                        <ErrorSpan>{t('required_field')}</ErrorSpan>
                      )}
                      {invalidLimit.instagram && (
                        <ErrorSpan>{t('error_limit')}</ErrorSpan>
                      )}
                    </div>
                    <div className="marginFile">
                      {type.instagram == 'video' && (
                        <UploadFileVideo
                          icon="video"
                          name="contentPerSocialNetwork.instagram"
                          handleVideo={handleImage}
                          className="socialNetworkFile"
                          content="instagram"
                          maxFileSize={30000}
                          maxFileDuration={60}
                          ref={register}
                          uploaded={uploadedInstagram}
                          infoBox={t('info_box_video_instagram')}
                          videosArray={
                            changeType.instagram
                              ? []
                              : [
                                  campaign.contentPerSocialNetwork?.instagram
                                    ?.videoUrl
                                ]
                          }
                          disabled={
                            !!(
                              campaign?.status === 'completed' ||
                              campaign?.status === 'activated'
                            )
                          }
                        />
                      )}
                      {type.instagram == 'image' && (
                        <UploadFileImageSimple
                          icon="photo"
                          name="contentPerSocialNetwork.instagram"
                          handleImage={handleImage}
                          className="socialNetworkFile"
                          content="instagram"
                          maxFileSize={5000}
                          ref={register}
                          infoBox={t('info_box_photo_instagram')}
                          imagesArray={
                            campaign.contentPerSocialNetwork?.instagram?.type ==
                            'image'
                              ? [
                                  campaign.contentPerSocialNetwork?.instagram
                                    ?.picturesUrls
                                ]
                              : []
                          }
                          disabled={
                            !!(
                              campaign?.status === 'completed' ||
                              campaign?.status === 'activated'
                            )
                          }
                        />
                      )}
                    </div>
                  </SocialNetworkContent>
                )}
                {checked.whatsapp && (
                  <SocialNetworkContent className="with-file">
                    <div className="SocialNetworkBox">
                      <div>
                        <Icon
                          size="small"
                          name="whatsapp"
                          color={Colors.whatsapp}
                        />
                      </div>
                      <label>{t('description_wa')}</label>
                      <div className="MarginEditText">
                        <Controller
                          name="contentWhatsapp"
                          control={control}
                          rules={{
                            maxLength: contentLimits.whatsapp
                          }}
                          defaultValue={
                            campaign.contentPerSocialNetwork?.whatsapp?.content
                          }
                          render={({
                            value = campaign.contentPerSocialNetwork?.whatsapp
                              ?.content,
                            onChange,
                            ref
                          }) => (
                            <EditorInput
                              isTwitter={false}
                              ref={ref}
                              className="content-editor"
                              placeholder=""
                              maxCharacters={contentLimits.whatsapp}
                              content={value}
                              onChange={e => {
                                onChange(e);
                              }}
                              disabled={
                                !!(
                                  campaign?.status === 'completed' ||
                                  campaign?.status === 'activated'
                                )
                              }
                            />
                          )}
                        />
                      </div>
                      <div className="styled_border">
                        <Icon
                          className={
                            type.whatsapp == 'image' || type.whatsapp == 'text'
                              ? 'icon-ins-active'
                              : 'icon-ins-noactive'
                          }
                          size="medium"
                          name="photo"
                          color={Colors.twitter}
                          onClick={() =>
                            campaign?.status !== 'completed' &&
                            onChangeTypeContent('whatsapp', 'image')
                          }
                        />
                        <Icon
                          className={
                            type.whatsapp == 'video' || type.whatsapp == 'text'
                              ? 'icon-ins-active'
                              : 'icon-ins-noactive'
                          }
                          size="medium"
                          name="video"
                          color={Colors.twitter}
                          onClick={() =>
                            campaign?.status !== 'completed' &&
                            onChangeTypeContent('whatsapp', 'video')
                          }
                        />
                      </div>
                      {errors.contentWhatsapp?.type === 'required' && (
                        <ErrorSpan>{t('required_field')}</ErrorSpan>
                      )}
                      {invalidLimit.whatsapp && (
                        <ErrorSpan>{t('error_limit')}</ErrorSpan>
                      )}
                    </div>
                    <div className="marginFile">
                      {type.whatsapp == 'video' && (
                        <UploadFileVideo
                          icon="video"
                          name="contentPerSocialNetwork.whatsapp"
                          handleVideo={handleImage}
                          className="socialNetworkFile"
                          content="whatsapp"
                          maxFileSize={30000}
                          maxFileDuration={60}
                          ref={register}
                          infoBox={t('info_box_video_whatsapp')}
                          uploaded={uploadedWhatsapp}
                          videosArray={
                            changeType.whatsapp
                              ? []
                              : [
                                  campaign.contentPerSocialNetwork?.whatsapp
                                    ?.videoUrl
                                ]
                          }
                          disabled={
                            !!(
                              campaign?.status === 'completed' ||
                              campaign?.status === 'activated'
                            )
                          }
                        />
                      )}
                      {type.whatsapp == 'image' && (
                        <UploadFileImageSimple
                          icon="photo"
                          name="contentPerSocialNetwork.whatsapp"
                          handleImage={handleImage}
                          className="socialNetworkFile"
                          content="whatsapp"
                          maxFileSize={5000}
                          ref={register}
                          infoBox={t('info_box_photo_whatsapp')}
                          imagesArray={
                            campaign.contentPerSocialNetwork?.whatsapp?.type ==
                            'image'
                              ? [
                                  campaign.contentPerSocialNetwork?.whatsapp
                                    ?.picturesUrls
                                ]
                              : []
                          }
                          disabled={
                            !!(
                              campaign?.status === 'completed' ||
                              campaign?.status === 'activated'
                            )
                          }
                        />
                      )}
                    </div>
                  </SocialNetworkContent>
                )}
                {checked.tiktok && (
                  <SocialNetworkContent className="with-file">
                    <div className="SocialNetworkBox">
                      <div>
                        <Icon
                          size="small"
                          name="tiktok"
                          color={Colors.tiktok}
                        />
                      </div>
                      <label>{t('description_tk')}</label>
                      <Tabs
                        className="multiple-contents"
                        orientation="horizontal"
                        tabsTitle={Object.keys(socialContent.tiktok).map(
                          (content, index) => ({
                            id: index + 1,
                            label: `${t('campaigns_page:option')} ${index + 1}`
                          })
                        )}
                        tab={socialContentTab.tiktok}
                        tabsContent={Object.keys(socialContent.tiktok).map(
                          (content, index) => ({
                            id: index + 1,
                            children: (
                              <div className="MarginEditText">
                                <Controller
                                  name={`contentTiktok${
                                    index === 0 ? '' : `${index + 1}`
                                  }`}
                                  control={control}
                                  rules={
                                    !socialContent.tiktok[
                                      `content${
                                        index === 0 ? '' : `${index + 1}`
                                      }`
                                    ]
                                      ? {
                                          required: checked.tiktok,
                                          maxLength: contentLimits.tiktok,
                                          pattern: no_mentions
                                        }
                                      : {
                                          required: false,
                                          maxLength: contentLimits.tiktok,
                                          pattern: no_characters
                                        }
                                  }
                                  disabled={
                                    !!(
                                      campaign?.status === 'completed' ||
                                      campaign?.status === 'activated'
                                    )
                                  }
                                  defaultValue={
                                    socialContent.tiktok[
                                      `content${
                                        index === 0 ? '' : `${index + 1}`
                                      }`
                                    ]
                                  }
                                  render={({
                                    value = socialContent.tiktok[
                                      `content${
                                        index === 0 ? '' : `${index + 1}`
                                      }`
                                    ],
                                    onChange,
                                    ref
                                  }) => (
                                    <EditorInput
                                      isTwitter={false}
                                      ref={ref}
                                      className="content-editor"
                                      placeholder=""
                                      maxCharacters={contentLimits.tiktok}
                                      content={value}
                                      onChange={e => {
                                        onChange(e);
                                        updateUrlValTiktok(e);
                                        handleChangeSocialContent(
                                          e,
                                          index,
                                          'tiktok'
                                        );
                                      }}
                                      disabled={
                                        !!(
                                          campaign?.status === 'completed' ||
                                          campaign?.status === 'activated'
                                        )
                                      }
                                    />
                                  )}
                                />
                              </div>
                            )
                          })
                        )}
                      />

                      <div className="styled_border">
                        <Icon
                          className={
                            type.tiktok == 'video' || type.tiktok == 'text'
                              ? 'icon-ln-active'
                              : 'icon-ln-noactive'
                          }
                          size="medium"
                          name="video"
                          color={Colors.twitter}
                          onClick={() =>
                            campaign?.status !== 'completed' &&
                            onChangeTypeContent('tiktok', 'video')
                          }
                        />
                        <div className="tabs-controls">
                          {Object.keys(socialContent.tiktok).length < 3 && (
                            <Icon
                              className={'icon-ln-active'}
                              size="medium"
                              name="add"
                              color={Colors.twitter}
                              onClick={() => addSocialContent('tiktok')}
                            />
                          )}
                          {Object.keys(socialContent.tiktok).length > 1 && (
                            <Icon
                              className={'icon-ln-noactive'}
                              size="medium"
                              name="less"
                              color={Colors.twitter}
                              onClick={() => removeSocialContent('tiktok')}
                            />
                          )}
                        </div>
                      </div>
                      {errors.contentTiktok?.type === 'required' && (
                        <ErrorSpan>{t('required_field')}</ErrorSpan>
                      )}
                      {(errors.contentTiktok?.type === 'pattern' ||
                        !onTiktok.isInputValid) && (
                        <ErrorSpan>{t('pattern_tiktok')}</ErrorSpan>
                      )}
                      {invalidLimit.tiktok && (
                        <ErrorSpan>{t('error_limit')}</ErrorSpan>
                      )}
                    </div>
                    <div className="marginFile">
                      {type.tiktok == 'video' && (
                        <UploadFileVideo
                          icon="video"
                          name="contentPerSocialNetwork.tiktok"
                          handleVideo={handleImage}
                          className="socialNetworkFile"
                          content="tiktok"
                          ref={register}
                          maxFileSize={30000}
                          maxFileDuration={300}
                          infoBox={t('info_box_video_tiktok')}
                          uploaded={uploadedTiktok}
                          videosArray={
                            changeType.tiktok
                              ? []
                              : [
                                  campaign.contentPerSocialNetwork?.tiktok
                                    ?.videoUrl
                                ]
                          }
                          disabled={
                            campaign?.status === 'completed' ||
                            campaign?.status === 'activated'
                              ? true
                              : false
                          }
                        />
                      )}
                    </div>
                  </SocialNetworkContent>
                )}
              </div>
            </FormTextArea>
            {campaign?.status !== 'completed' &&
              campaign?.status !== 'activated' && (
                <MarginButton>
                  {!loader ? (
                    <Button value={t('edit_campaign')} variant="primary" />
                  ) : (
                    <Spinner name="sign-up" />
                  )}
                </MarginButton>
              )}
          </InputsWrapper>
        </FormWrapper>
      </form>
    </SectionWrapper>
  );
};

export default EditCampaignForm;
