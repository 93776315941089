import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FacebookShareWrapper, AdviceBox, ShareActionsWrapper } from './Styled';
import Button from 'components/buttons/Button';

import { usePostActions } from '../PostActions';
import {
  AcceptPostRequest,
  verifyTokenSocialNetwork
} from 'services/rest/post';

import FacebookPrivacyEs from 'images/icons/facebook_privacy_es.svg';
import FacebookPrivacyEn from 'images/icons/facebook_privacy_en.svg';
import FacebookPrivacyPt from 'images/icons/facebook_privacy_pt.svg';

import FacebookInstructionsEs from 'images/backgrounds/share_fb_es.gif';
import FacebookInstructionsEn from 'images/backgrounds/share_fb_en.gif';
import FacebookInstructionsPt from 'images/backgrounds/share_fb_pt.gif';
import { setInvalidTokenCurrent } from 'redux/slices/InvalidToken/InvalidTokenSlice';
import { CampaignParams } from 'pages/CampaignDetailPage/CampaignDetailPage';
import { urlFacebook } from 'constants/setup.constants';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { selectCompany } from 'redux/slices/Company/CompanySlice';

const privacyImages: Record<string, string> = {
  es: FacebookPrivacyEs,
  en: FacebookPrivacyEn,
  pt: FacebookPrivacyPt
};

const shareInstructions: Record<string, string> = {
  es: FacebookInstructionsEs,
  en: FacebookInstructionsEn,
  pt: FacebookInstructionsPt
};
export interface FacebookShareCardProps {
  postId: number;
  originalContent: string;
  provider: string;
  setShowButton?: any;
  getRankingData?: any;
  setPublicationLoader: any;
}

const FacebookShareCard: React.FC<FacebookShareCardProps> = props => {
  const { campaignId } = useParams<CampaignParams>();
  const { currentUser } = useSelector(selectUser);
  const { company } = useSelector(selectCompany);
  const dispatch = useDispatch();
  const {
    originalContent,
    postId,
    provider,
    setShowButton,
    getRankingData,
    setPublicationLoader
  } = props;
  const { t, i18n } = useTranslation([
    'content_editor',
    'validations',
    'settings'
  ]);
  const { onPublishPost, onErrorPost } = usePostActions();
  const [loaderShare, setLoaderShare] = useState(false);
  const [loaderFinish, setLoaderFinish] = useState(false);

  const onClickShare = async () => {
    try {
      setLoaderShare(true);
      const { data } = await verifyTokenSocialNetwork(
        provider,
        currentUser?.token
      );
      if (data) {
        setLoaderShare(false);
        window.open(
          `https://facebook.com/sharer/sharer.php?u=${originalContent}&display=popup&ref=plugin&src=post`,
          'popup',
          'width=300,height=400,left=100,top=100,resizable=1'
        );
      }
    } catch (error: any) {
      setLoaderShare(true);
      const errorMessage = error.message || String(error);
      if (errorMessage.includes('permisos')) {
        dispatch(setInvalidTokenCurrent(provider));
        const invalidToken = provider;
        return (
          <Fragment>
            <p>{`${t('social_network:token_permissions', {
              invalidToken
            })}`}</p>
            <Button
              value={t('networks_list:update')}
              variant="tertiary"
              as="a"
              size="big"
              onClick={() => conect(provider)}
              target="_blank"
            />
          </Fragment>
        );
      }
    }
  };

  const conect = (conect: string) => {
    switch (conect) {
      case 'facebook':
        window.location.href = urlFacebook(
          company?.facebookCredentials.apiKey,
          conect
        );
        break;
    }
  };

  const onPublish = async () => {
    setLoaderFinish(true);
    setPublicationLoader(true);
    const { data, errors } = await AcceptPostRequest(
      Number(campaignId),
      originalContent,
      provider,
      currentUser?.token,
      '',
      false,
      ''
    );
    if (data) {
      onPublishPost(postId, data.data);
      setLoaderFinish(false);
      setPublicationLoader(false);
      setShowButton('published');
      getRankingData();
    }
    if (errors) {
      onErrorPost(t('settings:error_admin'));
      if (errors && errors.data) {
        onErrorPost(errors.data);
      }
      setLoaderFinish(false);
      setPublicationLoader(false);
      setShowButton('pending');
    }
  };

  return (
    <FacebookShareWrapper>
      <h2>{t('facebook_steps')}</h2>
      <AdviceBox>
        <ol>
          <li className="share">
            {t('click_on')}&nbsp;
            <strong>Share</strong>
            {t('share_facebook_content')}
          </li>

          <li>
            <span className="public-img">
              {t('check_post_is_public')}&nbsp;
              <img src={privacyImages[i18n.language]} alt="Facebook privacy" />
              &nbsp;{t('see_gif')}
            </span>
          </li>

          <li className="finish">
            {t('click_on')}&nbsp;
            <strong>{t('finish')}</strong>
            {t('sum_points')}
          </li>
        </ol>
      </AdviceBox>
      <img
        className="fb-share-instructions"
        src={shareInstructions[i18n.language]}
        alt="Facebook share instructions"
      />
      {company?.company_permission.allowFacebook && (
        <ShareActionsWrapper>
          <Button
            icon="facebook"
            variant="facebook"
            value="Share"
            size="medium"
            onClick={onClickShare}
            loader={loaderShare}
          />
          <Button
            onClick={onPublish}
            className="finish"
            value={t('finish')}
            size="medium"
            loader={loaderFinish}
          />
        </ShareActionsWrapper>
      )}
    </FacebookShareWrapper>
  );
};

export default FacebookShareCard;
