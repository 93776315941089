import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser, selectUserInfo } from 'redux/slices/userSlice';

declare global {
  interface Window {
    tikket: any;
  }
}

const TikketScript: React.FC = () => {
  const { currentUser } = useSelector(selectUser);

  const username =
    currentUser.role === 'admin'
      ? currentUser.user.username
      : `${currentUser.user.name} ${currentUser.user.lastName}`;

  useEffect(() => {
    (function (window, document) {
      window.tikket = {};
      window.tikket.id = 'b557be7c-ee5a-44f9-a0d4-2ed3bd3f7d90';
      window.tikket.user = {
        name: username,
        email: currentUser.user.email
      };

      const elt = document.createElement('script');
      elt.type = 'text/javascript';
      elt.async = true;
      elt.src = 'https://widget.tikket.net/shim.js';
      const before = document.getElementsByTagName('script')[0];
      if (before && before.parentNode) {
        before.parentNode.insertBefore(elt, before);
      }
    })(window, document);
  }, []);

  return null; // No devolvemos ningún contenido adicional
};

export default TikketScript;
